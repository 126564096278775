<div ngbAccordion #accordion="ngbAccordion">
  <div ngbAccordionItem="one" [collapsed]="false">
    <h2 ngbAccordionHeader>
      <button ngbAccordionButton>Contract Basics&nbsp;
        <div *ngIf="editmode === true">
          <span>Edit: Contract ID#{{this.id}}</span>
        </div>
      </button>
    
    </h2>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody>
        <ng-template>
            <div class="row mb-4">
              <div class="col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-placeholder" for="state">State *</label>
                  <div ngbDropdown>
                    <button type="button" class="form-control" id="state" style="text-align:left"  ngbDropdownToggle>
                      {{selectedState}} 
                    </button>
                    <div ngbDropdownMenu>
                      <div style="width:100%">
                                <input
                                class="form-control"
                                type="text"
                                name="search"
                                [(ngModel)] ="state"
                                autocomplete="off"
                                placeholder="Search"
                                />
                      </div>
                      <button ngbDropdownItem *ngFor="let state of stateList  | filter: state" 
                    
                        value = {{state.code}}
                        (click)="SelectState(state.code)">
                        {{ state.name }}
                        
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                    <label class="form-control-placeholder" required for = "county">County</label>
                    <input type="text" id="county" [(ngModel)]="county" class="form-control"/>
                </div>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                      <label class="form-control-placeholder" for = "market">Market</label>
                      <input type="text" id="market" [(ngModel)]="market" class="form-control"/>
                </div>
              </div>


              <div class="col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-placeholder" for="tin">TIN *</label>
                  <input type="text" id="tin" [(ngModel)]="tin"  class="form-control" 
                  (keypress)="integerOnly($event)" maxlength="9"/>
                  <!-- <div ngbDropdown>
              
                    <button type="button" class="form-control" id="tin" style="text-align:left"  ngbDropdownToggle>
                      {{selectedTIN}}
                    </button>
              
                    <div ngbDropdownMenu>
              
                      <div style="width:100%">
                                <input
                                class="form-control"
                                type="text"
                                name="search"
                                [(ngModel)] ="tin"
                                autocomplete="off"
                                placeholder="Search"
                                />
                      </div>
              
                      <button ngbDropdownItem *ngFor="let tin of tinList | filter: tin" 
                    
                        value = {{tin.code}}
                        (click)="SelectTIN(tin.code)">
                        {{ tin.code }}
                      </button>
                    </div>
                  </div> -->
                </div> 
              </div>
                
              <div class="col-sm-12 col-md-6 col-lg-4">

                <div class="form-group">
                  <label class="form-control-placeholder" for="tinname">TIN Name *</label>
                  <input type="text" id="tinname" [(ngModel)]="tinname" class="form-control" maxlength="250"/>

                  <!-- <div ngbDropdown>

                    <button type="button" class="form-control" id="tinname" style="text-align:left"   ngbDropdownToggle>
                      {{selectedTINName}}
                    </button>

                    <div ngbDropdownMenu>

                    <div style="width:100%">
                              <input
                              class="form-control"
                              type="text"
                              name="search"
                              [(ngModel)] ="tinname"
                              autocomplete="off"
                              placeholder="Search"
                              />
                    </div>

                      <button ngbDropdownItem *ngFor="let tin of tinnamesList | filter: tinname" 
                    
                        value = {{tin.code}}
                        (click)="SelectTINName(tin.code)">
                        {{ tin.code }}
                      </button>
                    </div>
                    </div>-->
                  
                </div> 
              </div>

              <div class="col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-placeholder" for="historicaltinname">Historical TIN Name</label>
                  <input type="text" id="historicaltinname" [(ngModel)]="historicaltinname" class="form-control" maxlength="250"/>
                </div> 
              </div>

                <div class="col-sm-12 col-md-6 col-lg-4">
                  <div class="form-group">
                    <label class="form-control-placeholder" for="eoc">EOC *</label>
                    <div ngbDropdown>
                      <button type="button" class="form-control" id="eoc" style="text-align:left"   ngbDropdownToggle>
                        {{selectedEOC}}
                      </button>
                    <div ngbDropdownMenu>

                    <div style="width:100%">
                      <input
                      class="form-control"
                      type="text"
                      name="search"
                      [(ngModel)] ="eoc"
                      autocomplete="off"
                      placeholder="Search"
                      />
                    </div>

                    <button ngbDropdownItem *ngFor="let eoc of EOCList | filter: eoc" 
                  
                      value = {{eoc.eocid}}
                      (click)="SelectEOC(eoc.eocid)">
                      {{ eoc.eocdesc }}
                    </button>
                  </div>
                </div>
                  
                  </div>
                </div>

        <div class="col-sm-12 col-md-6 col-lg-4">

          <div class="form-group">
            <label class="form-control-placeholder" for="indicator">Indicator *</label>

            <div ngbDropdown>

              <button type="button" class="form-control" id="indicator" style="text-align:left"  ngbDropdownToggle>
                {{selectedIndicator}}
              </button>

              <div ngbDropdownMenu>

                <div style="width:100%">
                          <input
                          class="form-control"
                          type="text"
                          name="search"
                          [(ngModel)] ="indicator"
                          autocomplete="off"
                          placeholder="Search"
                          />
              </div>

                <button ngbDropdownItem *ngFor="let indicator of IndicatorsList| filter: indicator" 
              
                  value = {{indicator.code}}
                  (click)="SelectIndicator(indicator.value)">
                  {{ indicator.value }}
                </button>
            </div>
          </div>
            
        </div>
      </div>



              <div class="col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-placeholder" for="contractStartDate"
                    >Contract Start Date *</label
                  >
                  <div class="input-group">
                  <input
                    id="contractStartDate"
                    class="form-control"
                    placeholder="yyyy-mm-dd"
                    name="contractStartDate"
                    [(ngModel)] = "csdmodel"
                    ngbDatepicker
                    #contractStartDate="ngbDatepicker"        
                  />
                  <button
                    class="btn btn-outline-secondary bi bi-calendar3"
                    (click)="contractStartDate.toggle()"
                                          (focus)="LoadStartDate()"
                    type="button"
                  ></button>
                                          </div>
                           </div>
              </div>
            

              <div class="col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-placeholder" for="contractEndDate"
                    >Contract End Date *</label
                  >
                  <div class="input-group">
                  <input
                    id="contractEndDate"
                    class="form-control"
                    placeholder="yyyy-mm-dd"
                    name="contractEndDate"
                    [(ngModel)] = "cedmodel"
                    ngbDatepicker
                    #contractEndDate="ngbDatepicker"        
                  />
                  <button
                    class="btn btn-outline-secondary bi bi-calendar3"
                    (click)="contractEndDate.toggle()"
                                          (focus)="LoadEndDate()"
                    type="button"
                  ></button>
                                          </div>
                           </div>
              </div>
          

            <div class="col-sm-12 col-md-6 col-lg-4">
              <div class="form-group">
                      <label class="form-control-placeholder" for="lowoutliervalue">Low Outlier Value *</label>
                      <input type="number" id="lowoutliervalue"  [(ngModel)]="lowoutliervalue" 
                      class="form-control"
                       (keypress)="decimalOnly($event)" maxlength=10 (change) ="valuecheck()" >
              </div>
          </div>
        
          <div class="col-sm-12 col-md-6 col-lg-4">
              <div class="form-group">
                      <label class="form-control-placeholder" for="highoutliervalue">High Outlier Value *</label>
                      <input id="highoutliervalue"  [(ngModel)]="highoutliervalue"  type="text" class="form-control"  
                      (keypress)="decimalOnly($event)" maxlength=10 (change) ="valuecheck()">
              </div>
          </div>
        
          <div class="col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                      <label class="form-control-placeholder" for="baselinecosttarget">Baseline Cost Target *</label>
                      <input id="baselinecosttarget"  [(ngModel)]="baselinecosttarget"  type="text" class="form-control"
                      (keypress)="decimalOnly($event)" maxlength=10>
                </div>
          </div>
        
          <div class="col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                      <label class="form-control-placeholder" for="gainsharingpercent">Gain Sharing <br>Percentage *</label>
                      <input id="gainsharingpercent"  [(ngModel)]="gainsharingpercent"  type="text" class="form-control"  
                      (keypress)="decimalOnly($event)" maxlength=10>
                </div>
          </div>
        
          <div class="col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                      <label class="form-control-placeholder" for="sharedoppertunitypercent">Shared opportunity percentage Limit *</label>
                      <input id="sharedoppertunitypercent"  [(ngModel)]="sharedoppertunitypercent"  type="text" class="form-control" 
                      (keypress)="decimalOnly($event)" maxlength=10>
                </div>
          </div>
 
          <!-- <div class="col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                      <label class="form-control-placeholder" for="configversion">Config<br> Version *</label>
                      <input id="configversion"  [(ngModel)]="configversion" (keypress)="alphanumeric_DOT_Only($event)" 
                       type="text" class="form-control" maxlength=6>
                </div>
          </div> -->
        

          <div class="col-sm-12 col-md-6 col-lg-4">
            <div class="form-group">
                  <label class="form-control-placeholder" for="algorithmversion">Algorithm <br>Version *</label>
                  <input id="algorithmversion"  [(ngModel)]="algorithmversion" (keypress)="alphanumeric_DOT_Only($event)"  
                  type="text" 
                  class="form-control" maxlength=6>
            </div>
          </div>

            <div class="col-sm-12 col-md-6 col-lg-4">
              <div class="form-group">
                <label class="form-control-placeholder" for="pcdb">Pre Contracting DBName *</label>

                <div ngbDropdown>
                  <button type="button" class="form-control" id="pcdb" style="text-align:left" ngbDropdownToggle>
                    {{selectedPCDB}}
                  </button>

                <div ngbDropdownMenu>
                    <div style="width:100%">
                      <input
                      class="form-control"
                      type="text"
                      name="search"
                      [(ngModel)] ="pcdb"
                      autocomplete="off"
                      placeholder="Search"
                      />
                    </div>

                    <button ngbDropdownItem *ngFor="let pcdb of PCDBList | filter: pcdb" 
                  
                      value = {{pcdb.code}}
                      (click)="selectPCDB(pcdb.code)">
                      {{ pcdb.code }}
                    </button>
                  </div>
                </div>
              
              </div>
            
            </div>
            <div class="col-sm-12 col-md-6 col-lg-4">
              <div class="form-group" *ngIf="isTextInputVisible">
                    <label class="form-control-placeholder" for="precontractdbname">Pre Contracting DBName</label>
                    <input id="precontractdbname"  [(ngModel)]="precontractdbname" (keypress)="alphanumeric_DOT_Only($event)" 
                    type="text" class="form-control" maxlength=250 >
              </div>
            </div>
        
                  <div>
                    <button class="btn btn-sm btn-outline-primary me-2"
                      style="background-color: #002060; color:#ffffff;" 
                      (click)="validateContractbasis()"
                      (click)="accordion.toggle('two')"
                      (click)="accordion.collapse('one')"
                      > Next
                    </button>
                  </div>
        </div>

        </ng-template>



      </div>
    </div>
  </div>


  <div ngbAccordionItem="two">
    <h2 ngbAccordionHeader>
      <button ngbAccordionButton>Cost Targets</button>
    </h2>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody>
        <ng-template>
         
            <div class="row mb-4">
              
              <div class="col-sm-12 col-md-6 col-lg-4">

                <div class="form-group">
                  <label class="form-control-placeholder" for="measurementperiod">Measurement Period *</label>
                

                  <div ngbDropdown>
                    <button type="button" class="form-control" id="measurementperiod" style="text-align:left"  ngbDropdownToggle>
                      {{selectedMPCT}}
                    </button>

                    <div ngbDropdownMenu aria-labelledby="measurementperiod">
                      <button ngbDropdownItem *ngFor="let MP of MPList" 
                        value = {{MP.code}}
                        (click)="SelectMPCT(MP.code)">
                        {{ MP.code }}
                      </button>
                    </div>

                   

                    </div></div>


                    


                    </div>
                  
                  
                    <div class="col-sm-12 col-md-6 col-lg-4">
                      <div class="form-group">
                                     <label class="form-control-placeholder" for = "lowcostthreshold" >Low Cost Threshold *</label>
                                                    <input type="text" id="lowcostthreshold"
                                                     [(ngModel)]="lowcostthreshold" 
                                                     class="form-control"
                                                     (keypress)="decimalOnly($event)"
                                                     maxlength="10"
                                                     />
                      </div>
       </div>


       <div class="col-sm-12 col-md-6 col-lg-4">
        <button class="btn btn-primary" style="background-color: #002060;" 
        (click)='addCostTargetGrid()'>Add</button>
   </div>
       
                  
                  
                  </div>

                  <div class="col-sm-12 col-md-6 col-lg-4">
                    <table class=".table table-striped">
                      <tr>
                          <th *ngFor="let header of usermodel.columns">
                              {{ header.label }}
                          </th>
                      </tr>
                  
                  
                  <tr *ngFor="let costtarget of costtargettable">
                      <td>
                        <input type="button" class="btn btn-danger"  value="X" (click)="onRemove(costtarget)" >
                        
                      </td>
                      
                    <td>
                      {{ costtarget.mp }}
                    </td>
                     
                    <td>
                      {{ costtarget.lct }}
                    </td>
                    </tr>
                    </table>

                    <button class="btn btn-sm btn-outline-primary me-2"
                    style="background-color: #002060; color:#ffffff;" 
                    (click)="accordion.toggle('three')"
                    (click)="accordion.collapse('two')"
                    (click)="validateCostTarget()"
                    > Next
                  </button>
            
            
                  </div>
                  
        </ng-template>


       
    
      </div>
    </div>
  </div>



  <div ngbAccordionItem="three">
    <h2 ngbAccordionHeader>
      <button ngbAccordionButton>Quality Details</button>
    </h2>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody>
        <ng-template>
         
            <div class="row mb-4">
              
              <div class="col-sm-12 col-md-6 col-lg-4">

                <div class="form-group">
                  <label class="form-control-placeholder" for="measurementperiodqd">Measurement Period *</label>
                

                  <div ngbDropdown>
                    <button type="button" class="form-control" id="measurementperiodqd" style="text-align:left"   ngbDropdownToggle>
                      {{selectedMPQD}}
                    </button>

                    <div ngbDropdownMenu aria-labelledby="measurementperiodqd">
                      <button ngbDropdownItem *ngFor="let MP of MPList" 
                        value = {{MP.code}}
                        (click)="SelectMPQD(MP.code)">
                        {{ MP.code }}
                      </button>
                    </div>

                   

                    </div></div>


                    


                    </div>
                  
                  
                    <div class="col-sm-12 col-md-6 col-lg-4">
                      <div class="form-group">
                        <label class="form-control-placeholder" required for = "qualitymetricnumber">Quality Metric #. *</label>
                        <input type="text" id="qualitymetricnumber"  (keypress)="integerOnly($event)"  
                        [(ngModel)]="qualitymetricnumber" 
                         class="form-control"
                        (keypress)="integerOnly($event)"
                         maxlength="3"
                         
                         />
                      
                </div>
       </div>

       <div class="col-sm-18 col-md-5 col-lg-4">
        <div class="form-group">
        <label class="col-sm-18 col-md-5 col-lg-12" style="width:550px" required for = "performancemeasurethreshold">Performance Measure Threshold *</label>
          <input type="text" id="performancemeasurethreshold"  (keypress)="decimalOnly($event)"  
          [(ngModel)]="performancemeasurethreshold"  
          class="form-control"
          (keypress)="decimalOnly($event)"
          maxlength="10"
          />
        </div>
        </div>

        <div class="col-sm-12 col-md-6 col-lg-8">
          <div class="form-group">
          <label class="form-control-placeholder" required for = "baselineperformance">Baseline<br>Performance *</label>
            <input type="text" class="form-control" id="baselineperformance" (keypress)="decimalOnly($event)"    
            maxlength="10"
            [(ngModel)]="baselineperformance"  class="form-control"/>
          </div>
          </div>

          <div class="col-sm-12 col-md-6 col-lg-4">

            <div class="form-group">
              <label class="form-control-placeholder" for="tieringqd">Tiering<br>Indicator *</label>
            

              <div ngbDropdown>
                <button type="button" class="form-control" id="tieringqd" style="text-align:left"  ngbDropdownToggle>
                  {{selectedTier}}
                </button>

                <div ngbDropdownMenu aria-labelledby="tieringqd">
                  <button ngbDropdownItem *ngFor="let tier of qmTierList" 
                    value = {{tier.code}}
                    (click)="SelectTiering(tier.value)">
                    {{ tier.code }}
                  </button>
                </div>

               

                </div></div>


                


                </div>

       <div class="col-sm-12 col-md-6 col-lg-4">
        <button class="btn btn-primary" style="background-color: #002060;" 
        (click)='addQualityDetailsGrid()'>Add</button>
   </div>
       
                  
                  
                  </div>

                  <div class="col-sm-12 col-md-6 col-lg-4">
                    <table class=".table table-striped">
                      <tr>
                          <th *ngFor="let header of usermodelQD.columns">
                              {{ header.label }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </th>
                      </tr>
                  
                  
                   <tr *ngFor="let qualitydetail of qualitydetailstable">
                      <td>
                        <input type="button" class="btn btn-danger"  value="X" (click)="onRemoveQD(qualitydetail)" >
                      </td>
                      
                    <td>
                      {{ qualitydetail.mp }}
                    </td>
                     
                    <td>
                      {{ qualitydetail.qmn }}
                    </td>
                    
                    <td>
                      {{ qualitydetail.pmt }}
                    </td>
                     
                    <td>
                      {{ qualitydetail.blp }}
                    </td>
                    <td>
                      {{ qualitydetail.ti }}
                    </td>
                    </tr>
                    </table>
      

                    <button class="btn btn-sm btn-outline-primary me-2"
                    style="background-color: #002060; color:#ffffff;" 
                    (click)="saveContract()"
                    (click)="accordion.collapse('three')"
                    > Submit
                  </button>
            
            
                  </div>
                  
        </ng-template>


       
    
      </div>
    </div>
  </div>
</div>


